import BaseBean from "@/utils/BaseBean";

export interface IPortalDataObj {
    utilInst:PortalUtil
    refMap:Map<string,any>
    otherParams:any
}

export default class PortalUtil extends BaseBean{
    public dataObj:IPortalDataObj

    constructor(proxy:any,dataObj:IPortalDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    //初始化portalData
    public async initPortalData():Promise<void>{
        // this.dataObj.userPortals = [
        //   { id: "sys_role", url: "/role", caption: "角色" }
        // ];
        // this.dataObj.portalData = [
        //   { id: "sys_role", url: "/role", caption: "角色" },
        //   { id: "sys_user", url: "/user", caption: "登录帐号" }
        // ];
        //请求后台，获取用户可用portal和已设置portal
        let res = await this.utils.Api.loadUserPortals();
        this.dataObj.otherParams.portalData = res.portalData;//可用portal
        this.dataObj.otherParams.userPortals = res.userPortals;//已设置portal
        if(res.colNum)this.dataObj.otherParams.colNum = res.colNum;//用户布局设置portal分几列展示
        //portal的辅助对象refreshHelper,内容为 portalID:此刻时间，然后portal按id绑定refreshHelper中对应项作为key，
        //刷新的时候，改变refreshHelper当中对应portalID的时间即可达到效果（因为key的值就被改变了）
        this.dataObj.otherParams.portalData.forEach((row:any)=> {
            this.dataObj.otherParams.refreshHelper[row.id] = new Date();
        }, this.dataObj);
    }
    //重新计算每个portal的高度(根据portal的个数和portal可显示区域高度动态计算每个portal的高度)
    public calPortalHeight():string{
        //30是portal工具条高度，4是工具条上下margin，1是工具条下边框，这些可以通过谷歌浏览器查看dom图形得到
        let height=window.innerHeight-(30+4*2+1+50+1+this.utils.Const.topHeight);
        let num=this.dataObj.otherParams.userPortals.length;
        if(num==0)return height+'px';
        if(num%this.dataObj.otherParams.colNum==0){
            return height/(num/this.dataObj.otherParams.colNum)+'px';
        }else{
            return height/(parseInt(String(num / this.dataObj.otherParams.colNum))+1)+'px';
        }
    }

    //------------------------设置portal显示的方式(一行几个)
    public portalColumnConfigHandler(num:number):void{
        this.dataObj.otherParams.colNum =num;//colNum的改变会触发计算属性portalItem的改变，而portalItem改变了就会重新渲染portal的布局方式
        this.dataObj.otherParams.portalHeight=this.calPortalHeight();//重新计算每个portal的高度
    }

    //------------------------打开配置portal的弹出框，把已经配置好的portal勾选上
    public portalConfigOpenedHandler():void{
        this.dataObj.otherParams.portalData.forEach((row:any)=>{
            if (this.checkPortalIsChoose(row.id))this.dataObj.refMap.get('portalTableRef').toggleRowSelection(row);
        });
    }
    //检查portalId是否在用户自己配置的portal中
    public checkPortalIsChoose(id:string):boolean{
        return this.dataObj.otherParams.userPortals.some((item:any)=>{
            return item.id == id;
        });
    }
    //portal设置弹出框确认事件
    public portalConfigSureHandler():void {
        this.dataObj.otherParams.portalVisible = false;
        //这里用的是原生table，用以下方式获取已选择的行，如果用我们包装的gridtable，则或许已选择行的方式：portalTableRef.value.getSelection()
        let selRows = this.dataObj.refMap.get('portalTableRef').store.states.selection.value;
        // dataObj.userPortals.length = 0;//不能用这种方式清空数组，只能用splice，否则watch不到userPortals为0的情况
        this.dataObj.otherParams.userPortals.splice(0,this.dataObj.otherParams.userPortals.length);
        selRows.forEach((row:any)=> {
            if (!this.checkPortalIsChoose(row.id)) this.dataObj.otherParams.userPortals.push(row);//如果有新的portal加入，则放入到用户已选portal数组中
        });
        this.dataObj.otherParams.portalHeight=this.dataObj.utilInst.calPortalHeight();//重新计算每个portal的高度
    }

    //------------------------保存portal布局
    public async saveUserPortalHandler():Promise<void>{
        if(this.dataObj.otherParams.userPortals.length==0){
            this.utils.Tools.configBox({
                message:this.proxy.$t('portal.saveLayOutTip'),
                sureFn:async ()=> {
                    await this.doSaveUserPortal();
                }
            });
        }else{
            await this.doSaveUserPortal();
        }
    }
    public async doSaveUserPortal ():Promise<void>{
        let userPortalIds:Array<string> = [];
        this.dataObj.otherParams.userPortals.forEach((row:any)=>{
            userPortalIds.push(row.id);
        });

        let params = {
            userPortalIds: userPortalIds,
            colNum: this.dataObj.otherParams.colNum
        };
        let res = await this.utils.Api.saveUserPortals(params);
        if(res.result)this.utils.Tools.success({message: res.msg});
    }

    //刷新某个portal
    public refreshHandler(portalId:string):void{
        //portal刷新的时候，改变当前刷新portal的时间，记住，
        //computed属性监听对象时候，若对象的引导地址未改变，那么computed将不会检测到。
        //比如object中的某个key对应的value变化了，computed检测不出来。
        //所以我们把refreshHelper给重新搞一份，里面的值变化了，就会只刷新变化的那个portal。
        this.dataObj.otherParams.refreshHelper[portalId] = new Date();
        this.dataObj.otherParams.refreshHelper = Object.assign({}, this.dataObj.otherParams.refreshHelper);
    }
    //点击某个portal工具条上的更多，实现跳转到具体的tab上去
    public moreHandler(path:string):void{
        this.proxy.$router.replace({path: path});
    }
}